const Layout = () => import('@/layout/index.vue')

export default {
    path: '/system/permission',
    component: Layout,
    redirect: '/system/permission/list',
    meta: {
        i18n: 'route.system.permission.name',
        icon: 'ri:admin-line',
        auth: ['system_permission']
    },
    children: [
        {
            path: 'group',
            name: 'systemPermissionGroup',
            component: () => import('@/views/system/permission/group/list.vue'),
            meta: {
                i18n: 'route.system.permission.group',
                auth: ['system_permission_list'],
                cache: true
            }
        },
        {
            path: 'group/add',
            name: 'systemPermissionGroupAdd',
            component: () => import('@/views/system/permission/group/form.vue'),
            meta: {
                i18n: 'route.system.permission.group',
                auth: ['system_permission_list'],
                cache: true,
                sidebar: false,
                activeMenu: '/system/permission/group'
            }
        },
        ,
        {
            path: 'group/update/:id',
            name: 'systemPermissionGroupUpdate',
            component: () => import('@/views/system/permission/group/form.vue'),
            meta: {
                i18n: 'route.system.permission.group',
                auth: ['system_permission_list'],
                cache: true,
                sidebar: false,
                activeMenu: '/system/permission/group'
            }
        },
        {
            path: 'list',
            name: 'systemPermissionList',
            component: () => import('@/views/system/permission/treeList.vue'),
            meta: {
                i18n: 'route.system.permission.list',
                auth: ['system_permission_list'],
                cache: true
            }
        },
        {
            path: 'add',
            name: 'systemPermissionAdd',
            component: () => import('@/views/system/permission/form.vue'),
            meta: {
                i18n: 'route.system.permission.add',
                cache: true,
                auth: ['system_permission_add']
            }
        },
        {
            path: 'update/:id',
            name: 'systemPermissionUpdate',
            component: () => import('@/views/system/permission/form.vue'),
            meta: {
                i18n: 'route.system.permission.update',
                sidebar: false,
                activeMenu: '/system/permission/list',
                cache: true,
                auth: ['system_permission_update']
            }
        }

    ]
}
