const Layout = () => import('@/layout/index.vue')

export default {
    path: '/bee/channel',
    component: Layout,
    redirect: '/bee/channel/list',
    meta: {
        i18n: 'route.bee.channel.name',
        icon: 'ri:contract-line'
    },
    children: [
        {
            path: 'data',
            name: 'channelData',
            component: () => import('@/views/bee/channel/data.vue'),
            meta: {
                i18n: 'route.bee.channel.data',
                cache: true
            }
        },
        {
            path: 'list',
            name: 'channelManage',
            component: () => import('@/views/bee/channel/list.vue'),
            meta: {
                i18n: 'route.bee.channel.list',
                cache: true
            }
        },
        {
            path: 'rule/:channelId/:channelName',
            name: 'channelRules',
            component: () => import('@/views/bee/channel/rule.vue'),
            meta: {
                i18n: 'route.bee.channel.list',
                cache: true,
                sidebar: false,
                activeMenu: '/bee/channel/list'
            }
        },
        {
            path: 'view/:channelId',
            name: 'channelMainView',
            component: () => import('@/views/bee/channel/view.vue'),
            meta: {
                i18n: 'route.bee.channel.view',
                cache: false,
                sidebar: false,
                activeMenu: '/bee/channel/list'
            }
        },
        {
            path: 'channel/desc/:channelId/:id?',
            name: 'channelDesc',
            component: () => import('@/views/bee/channel/desc/index.vue'),
            meta: {
                i18n: 'route.bee.channel.desc',
                cache: false,
                sidebar: false,
                activeMenu: '/bee/channel/list'
            }
        },
        {
            path: 'explore',
            name: 'explore',
            component: () => import('@/views/bee/channel/explore/index.vue'),
            meta: {
                i18n: 'route.bee.channel.explore',
                cache: false
            }
        },
        {
            path: 'hit',
            name: 'hit',
            component: () => import('@/views/bee/channel/hit/index.vue'),
            meta: {
                i18n: 'route.bee.channel.hit',
                cache: false
            }
        }
    ]
}
