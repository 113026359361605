const Layout = () => import('@/layout/index.vue')

export default {
    path: '/intelligence/material',
    component: Layout,
    redirect: { name: 'materialList' },
    meta: {
        title: '物料信息',
        icon: 'ri:color-filter-line',
        auth: ['intelligence_material'],
        breadcrumb: false
    },
    children: [
        {
            path: 'list',
            name: 'materialList',
            component: () => import('@/views/intelligence/material/list.vue'),
            meta: {
                title: '价格走势',
                cache: true,
                auth: ['intelligence_material_his']
            }
        }
    ]
}
